<template>
  <b-container>
    <swiper class="swiper-navigations" :options="swiperOptions">
      <swiper-slide v-for="(item, index) in swiperData" :key="index">
        <b-img :src="item.image_url" class="w-100"
          :style="`cursor: ${item.meta && item.meta.url ? 'pointer' : 'default'}; display:block`"
          @click="handleClickAds(item.meta && item.meta.url)" />
      </swiper-slide>

      <!-- Add Arrows -->
      <div slot="button-next" class="swiper-button-next" />
      <div slot="button-prev" class="swiper-button-prev" />
      <div slot="pagination" class="swiper-pagination"></div>
    </swiper>
  </b-container>
</template>

<script>
import {
  BCard, BCardText, BContainer, BImg
} from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import 'swiper/css/swiper.css'


export default {
  title() {
    return "Welcome 🚀";
  },
  components: {
    BCard,
    BCardText,
    BContainer,
    BImg,
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  data() {
    return {
      data: Object,
      /* eslint-disable global-require */
      swiperData: [
      ],
      /* eslint-disable global-require */

      swiperOptions: {
        spaceBetween: 30,
        effect: 'fade',
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
        },
        autoHeight: true,
      },
    }
  },
  // eslint-disable-next-line consistent-return
  created() {
    const { phone_verified_at } = JSON.parse(localStorage.getItem("userData"));
    // eslint-disable-next-line camelcase
    if (!phone_verified_at) {
      return this.$router.push("/otp");
    }
  },
  mounted() {
    this.getAds()
  },
  methods: {
    handleClickAds(url) {
      if (url) {
        window.open(url, '_blank')
      }
    },
    async getAds() {
      try {
        let response = await this.$http.get('/sliders')
        this.swiperData = response.data.data
      } catch (err) {
        console.error(err);  // eslint-disable-line no-console
      } finally {
        this.isLoading = false;
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.card-body {
  width: 100vw;
}
</style>
